.ant-select-dropdown {
  margin-top: 10px;
  background: transparent !important;
}

.rc-virtual-list,
.ant-empty-small {
  background-color: #000000 !important;
  border: #cff54d 1px solid;
  border-radius: 10px;
  padding: 10px 5px;
}
.ant-select-item-option-content {
  color: #fff;
  justify-content: center;
  white-space: unset;
}

.ant-empty-small {
  margin: auto;
  color: #fff;
}

.ant-table-filter-dropdown {
  background-color: #000;
  border-radius: 10px;
}
.ant-dropdown-menu {
  background-color: #000;
}
.ant-dropdown-menu-item {
  color: white;
}
.ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span {
  font-weight: 700;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-item:focus,
.ant-dropdown-menu-item-selected {
  background-color: #000;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-color: none;
  background-color: #000;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  overflow-y: hidden;
}
.ant-checkbox-inner,
.ant-radio-inner {
  background-color: #000;
  width: 20px;
  height: 20px;
  background-size: 21px;
  background-repeat: no-repeat;
  border: none;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}
.ant-radio-inner:after {
  background-color: #cff54d;
}
.ant-table-filter-dropdown-btns {
  border-radius: 10px;
  border-top: none;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  border-radius: 10px;
  width: 172px;
  padding-top: 10px;
  overflow: auto;
}

.ant-table-filter-dropdown .ant-dropdown-menu::-webkit-scrollbar {
  width: 6px;
  background-color: #000000;
}

.ant-table-filter-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #3b3b3b;
  border-radius: 6px;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
  justify-content: center;
  background: url("../assets/vectors/vector-button.svg");
  background-repeat: no-repeat;
  color: #cff54d;
  font-weight: 600;
  width: 73px;
  border: none;
}
.ant-table-filter-dropdown-btns .ant-btn-link {
  color: #fff;
}
.ant-table-filter-dropdown-btns .ant-btn-link:hover,
.ant-table-filter-dropdown-btns .ant-btn-link:focus {
  color: #fff;
}
.ant-dropdown-menu-item {
  padding: 5px 32px;
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  left: 8px;
}

.ant-select-item-option {
  justify-content: center;
}

.select-token .ant-select-item-option-content {
  /* padding:0 4rem; */
  max-width: 100px !important;
  justify-content: space-between;
}
.select-token-filter .ant-select-item-option-content {
  /* padding:0 4rem; */
  max-width: 100px !important;
  justify-content: left;
}

.button-add {
  width: max-content;
  padding: 0;
  /* transform: translateX(16%); */
  position: relative;
  left: 15%;
}
@media (min-width: 1600px) {
  .button-add {
    /* transform: translateX(46%); */
    left: 22%;
  }
}
@media (max-width: 1024px) {
  .button-add {
    /* transform: translateX(46%); */
    left: 13%;
  }
}

@media (max-width: 576px) {
  .button-add {
    /* transform: translateX(46%); */
    left: 2%;
  }
}
