
@font-face {
  font-family: 'Bicyclette';
  src: local('Bicyclette'), url(../assets/font/Bicyclette-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'BicycletteRe';
  src: local('BicycletteRe'), url(../assets/font/Bicyclette-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Bicyclette Bold';
  src: local('Bicyclette Bold'), url(../assets/font/Bicyclette-Bold.ttf) format('truetype');
}
.ant-select-dropdown {
  background: rgba(43, 27, 78, 1);
  /* border: 1px solid rgba(129, 75, 246, 0.2); */
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.ant-select-item {
  background: transparent !important;
}

#root {
  /* background: #000; */
  overflow-x: hidden !important;
}

body {
  background-color: #000;
  color: #fff;
  font-family: Bicyclette;
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ant-btn.ant-btn-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn>.ant-btn-loading-icon {
  display: flex;
  align-items: center;
}
.ant-pagination-item-link{
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-table-filter-trigger.active{
  color: #9600B0;
}